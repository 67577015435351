<template>
  <div id="app">
    <SettingsBox
      ref="settings"
      v-bind:amount="getFiltered().length"
      v-bind:selected="selected"
      v-bind:clearSelected="function() { reset(); }"
    ></SettingsBox>
    <br>
    <div id="card-list">
      <NamedColor
        v-for="(namedColor, index) in getFiltered(true)"
        :key="index"
        :namedColor="namedColor"
        v-bind:onClick="function() { sort(namedColor) }"
      ></NamedColor>
    </div>
  </div>
</template>

<script>
import ncJSON from "./assets/colors.json";
import SettingsBox from "./components/SettingsBox.vue";
import NamedColor from "./components/NamedColor.vue";

export default {
  name: "App",
  data() {
    return {
      namedColors: [],
      selected: null
    };
  },
  components: {
    SettingsBox,
    NamedColor,
  },
  methods: {
    getFiltered(limit) {
      var filter = "";
      if (this.$refs.settings != null) {
        filter = this.$refs.settings.filter;
        if (limit) limit = this.$refs.settings.limit;
      }
      var filtered = this.namedColors;
      if (filter.length > 1) {
        filtered = this.namedColors.filter(function (v) {
          return v.name.includes(filter);
        });
      }
      return limit ? filtered.slice(0, 100) : filtered;
    },
    sort(selected) {
      this.selected = selected;
      var compare = function (a, b) {
        var selectedToAR = Math.abs(selected.color.r - a.color.r);
        var selectedToAG = Math.abs(selected.color.g - a.color.g);
        var selectedToAB = Math.abs(selected.color.b - a.color.b);
        var selectedToAAvg = (selectedToAR + selectedToAG + selectedToAB) / 3.0;

        var selectedToBR = Math.abs(selected.color.r - b.color.r);
        var selectedToBG = Math.abs(selected.color.g - b.color.g);
        var selectedToBB = Math.abs(selected.color.b - b.color.b);
        var selectedToBAvg = (selectedToBR + selectedToBG + selectedToBB) / 3.0;

        if (selectedToAAvg < selectedToBAvg) return -1;
        if (selectedToAAvg > selectedToBAvg) return 1;
        return 0;
      };

      this.namedColors = this.namedColors.sort(compare);
    },
    reset() {
      var nc = this.namedColors;
      for (let i = nc.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [nc[i], nc[j]] = [nc[j], nc[i]];
      }
      this.namedColors = nc;
      this.selected = null;
    }
  },
  mounted() {
    this.namedColors = ncJSON;
    this.reset();
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 5vh;
}

#card-list {
  width: 90vw;
  display: inline-block;
}
</style>
