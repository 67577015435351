<template>
  <div id="settings-box">
    <div class="settings-item">
      <div class="setting-half">
        <span class="text" style="float: right">filter colors:</span>
      </div>
      <div class="setting-half">
        <input
          type="search"
          v-model="filter"
          class="text"
          style="float: left; width: 20vh"
        />
      </div>
    </div>
    <div class="settings-item">
      <div class="setting-half">
        <span class="text" style="float: right"
          >limit amount shown:</span
        >
      </div>
      <div class="setting-half">
        <input
          type="checkbox"
          v-model="limit"
          style="float: left; width: 2vh; height: 2vh; margin-top: 0.4vh"
        />
      </div>
    </div>
    <br />
    <span class="text" style="font-family: RobotoSlabLight">{{
      getFilterText()
    }}</span> 
    <span v-if="selected != null" class="text" style="font-family: RobotoSlabLight">, sorting by similarity to {{selected.name}}</span>
    <br>
    <button v-if="filter.length > 0 || selected != null" v-on:click="clear()" id="reset-button">Reset</button>
  </div>
</template>

<script>
export default {
  name: "SettingsBox",
  data() {
    return {
      filter: "",
      limit: true,
    };
  },
  props: {
    amount: Number,
    selected: Object,
    clearSelected: Function
  },
  methods: {
    getFilterText() {
      if (this.amount == 0)
        return `found no colors with filter "${this.filter}"`;
      if (this.amount > 1 && this.filter != "")
        return `found ${this.amount} colors with filter "${this.filter}"`;
      return `loaded ${this.amount} colors`;
    },
    clear() {
        this.filter = "";
        this.limit = true;
        this.clearSelected();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.setting-half {
  width: 50%;
  margin: 1vh;
}

.settings-item {
  height: 5vh;
  display: flex;
}

.text {
  font-family: "RobotoSlab";
  font-size: 2vh;
}

@font-face {
  font-family: "RobotoSlab";
  src: local("RobotoSlab"),
    url(../fonts/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoSlabLight";
  src: local("RobotoSlab"),
    url(../fonts/RobotoSlab-Light.ttf) format("truetype");
}

#reset-button {
    margin-top:2vh;
    height: 5vh;
    width: 9vh;
    font-family: RobotoSlab;
    font-size: 2vh;
    color: white;
    background-color: black;
    border-style:none;
}
</style>